// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countdown-timer {
    font-size: 30px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin: 20px;
    padding: 20px;
    /* background: linear-gradient(to right, #4e54c8, #8f94fb); */
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.timer-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.timer {
    text-align: center;
    margin: 20px;
    animation: fadeIn 1s ease-in-out;
}

.timer-time {
    font-size: 100px;
    font-family: 'Arial', sans-serif;
    color: #fff;
    letter-spacing: 3px;
    /* background: rgba(0, 0, 0, 0.2); */
    padding: 10px 20px;
    border-radius: 10px;
}

.timer-label {
    margin-top: 10px;
    font-size: 20px;
    color: #ddd;
}

@media (max-width: 768px) {
    .timer-time {
        font-size: 60px;
    }

    .timer-label {
        font-size: 16px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}`, "",{"version":3,"sources":["webpack://./src/Component/countdown.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,6DAA6D;IAC7D,mBAAmB;IACnB,yCAAyC;AAC7C;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,gCAAgC;IAChC,WAAW;IACX,mBAAmB;IACnB,oCAAoC;IACpC,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,WAAW;AACf;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":[".countdown-timer {\n    font-size: 30px;\n    font-weight: bold;\n    color: #333;\n    text-align: center;\n    margin: 20px;\n    padding: 20px;\n    /* background: linear-gradient(to right, #4e54c8, #8f94fb); */\n    border-radius: 20px;\n    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);\n}\n\n.timer-container {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    flex-wrap: wrap;\n}\n\n.timer {\n    text-align: center;\n    margin: 20px;\n    animation: fadeIn 1s ease-in-out;\n}\n\n.timer-time {\n    font-size: 100px;\n    font-family: 'Arial', sans-serif;\n    color: #fff;\n    letter-spacing: 3px;\n    /* background: rgba(0, 0, 0, 0.2); */\n    padding: 10px 20px;\n    border-radius: 10px;\n}\n\n.timer-label {\n    margin-top: 10px;\n    font-size: 20px;\n    color: #ddd;\n}\n\n@media (max-width: 768px) {\n    .timer-time {\n        font-size: 60px;\n    }\n\n    .timer-label {\n        font-size: 16px;\n    }\n}\n\n@keyframes fadeIn {\n    from {\n        opacity: 0;\n    }\n\n    to {\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
