// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Resources/ProtestGuerrilla-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` 
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  @font-face {
    font-family: 'ProtestGuerrilla';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  .no-select::selection {
    background: transparent;
    color: inherit;
  }
  .no-select {
    user-select: none; /* Disable selection in most modern browsers */
    -webkit-user-select: none; /* Disable selection for Safari */
    -moz-user-select: none; /* Disable selection for Firefox */
    -ms-user-select: none; /* Disable selection for Internet Explorer */
  }

  @keyframes fade-in-out {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/Component/Bubble.css"],"names":[],"mappings":";EACE;IACE;eACW;EACb;EACA;IACE,+BAA+B;IAC/B,+DAAwE;IACxE,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,uBAAuB;IACvB,cAAc;EAChB;EACA;IACE,iBAAiB,EAAE,8CAA8C;IACjE,yBAAyB,EAAE,iCAAiC;IAC5D,sBAAsB,EAAE,kCAAkC;IAC1D,qBAAqB,EAAE,4CAA4C;EACrE;;EAEA;IACE;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;EACF","sourcesContent":[" \n  code {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n      monospace;\n  }\n  @font-face {\n    font-family: 'ProtestGuerrilla';\n    src: url('../Resources/ProtestGuerrilla-Regular.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n  }\n  \n  .no-select::selection {\n    background: transparent;\n    color: inherit;\n  }\n  .no-select {\n    user-select: none; /* Disable selection in most modern browsers */\n    -webkit-user-select: none; /* Disable selection for Safari */\n    -moz-user-select: none; /* Disable selection for Firefox */\n    -ms-user-select: none; /* Disable selection for Internet Explorer */\n  }\n\n  @keyframes fade-in-out {\n    0%, 100% {\n      opacity: 0;\n    }\n    50% {\n      opacity: 1;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
