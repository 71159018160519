// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Stalinist+One&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Play.css */

/* Lightning background */

.font-stalinist {
  font-family: "Stalinist One", cursive;
}

.play-container {
  /* background-image: url("../Resources/lightning.svg");  */
  background-size: cover;
  background-position: center;
  position: relative;
  margin-top: 0.1%;
}

/* Start Button neon glow effect */
.start-button {
  background-color: #7c3aed;
  box-shadow: 0 0 20px #988dac, 0 0 10px #9a8eae, 0 0 20px #968ca9;
  transition: all 0.3s ease;
}

.start-button:hover {
  box-shadow: 0 0 40px #7c3aed, 0 0 80px #7c3aed, 0 0 100px #7c3aed;
}

/* Blurred box effect */
.blurred-box {
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Play.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb,yBAAyB;;AAIzB;EACE,qCAAqC;AACvC;;AAEA;EACE,0DAA0D;EAC1D,sBAAsB;EACtB,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA,kCAAkC;AAClC;EACE,yBAAyB;EACzB,gEAAgE;EAChE,yBAAyB;AAC3B;;AAEA;EACE,iEAAiE;AACnE;;AAEA,uBAAuB;AACvB;EACE,2BAA2B;EAC3B,0CAA0C;EAC1C,2CAA2C;AAC7C","sourcesContent":["/* Play.css */\n\n/* Lightning background */\n\n@import url(\"https://fonts.googleapis.com/css2?family=Stalinist+One&display=swap\");\n\n.font-stalinist {\n  font-family: \"Stalinist One\", cursive;\n}\n\n.play-container {\n  /* background-image: url(\"../Resources/lightning.svg\");  */\n  background-size: cover;\n  background-position: center;\n  position: relative;\n  margin-top: 0.1%;\n}\n\n/* Start Button neon glow effect */\n.start-button {\n  background-color: #7c3aed;\n  box-shadow: 0 0 20px #988dac, 0 0 10px #9a8eae, 0 0 20px #968ca9;\n  transition: all 0.3s ease;\n}\n\n.start-button:hover {\n  box-shadow: 0 0 40px #7c3aed, 0 0 80px #7c3aed, 0 0 100px #7c3aed;\n}\n\n/* Blurred box effect */\n.blurred-box {\n  backdrop-filter: blur(10px);\n  border: 2px solid rgba(255, 255, 255, 0.2);\n  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
