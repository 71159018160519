// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
    .emoji-grid {
      grid-template-columns: repeat(3, 1fr); /* 3 emojis per row on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .emoji-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 emojis per row on very small screens */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Minigames/Minigame16/Search.css"],"names":[],"mappings":"AAAA;IACI;MACE,qCAAqC,EAAE,wCAAwC;IACjF;EACF;;EAEA;IACE;MACE,qCAAqC,EAAE,2CAA2C;IACpF;EACF","sourcesContent":["@media (max-width: 768px) {\n    .emoji-grid {\n      grid-template-columns: repeat(3, 1fr); /* 3 emojis per row on smaller screens */\n    }\n  }\n  \n  @media (max-width: 480px) {\n    .emoji-grid {\n      grid-template-columns: repeat(2, 1fr); /* 2 emojis per row on very small screens */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
