// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  position: relative;
  margin-bottom: 0.5rem;
  transition: all 0.3s ease;
}

.input-container label {
  position: absolute;
  top: 40%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 0.95rem;
  color: #888;
  transition: all 0.3s ease;
}

.input-container.active label,
.input-container input:focus + label {
  top: -10px;
  font-size: 0.8rem;
  color: #0ea5e9; /* cyan-500 color */
}

input {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #444;
  background-color: #111;
  color: white;
}

input:focus {
  outline: none;
  border-color: #0ea5e9;
}
`, "",{"version":3,"sources":["webpack://./src/Component/Login.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,2BAA2B;EAC3B,kBAAkB;EAClB,WAAW;EACX,yBAAyB;AAC3B;;AAEA;;EAEE,UAAU;EACV,iBAAiB;EACjB,cAAc,EAAE,mBAAmB;AACrC;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,sBAAsB;EACtB,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB","sourcesContent":[".input-container {\n  position: relative;\n  margin-bottom: 0.5rem;\n  transition: all 0.3s ease;\n}\n\n.input-container label {\n  position: absolute;\n  top: 40%;\n  left: 10px;\n  transform: translateY(-50%);\n  font-size: 0.95rem;\n  color: #888;\n  transition: all 0.3s ease;\n}\n\n.input-container.active label,\n.input-container input:focus + label {\n  top: -10px;\n  font-size: 0.8rem;\n  color: #0ea5e9; /* cyan-500 color */\n}\n\ninput {\n  padding: 1rem;\n  border-radius: 0.5rem;\n  border: 1px solid #444;\n  background-color: #111;\n  color: white;\n}\n\ninput:focus {\n  outline: none;\n  border-color: #0ea5e9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
