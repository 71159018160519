import React from 'react'
import './Play.css'
import StoryGame from '../StoryGame'

const Play = () => {
  return (
    <div className="">
      <StoryGame />
    </div>
  )
}

export default Play
